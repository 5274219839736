export const FETCH_QUESTIONNAIRE = "FETCH_QUESTIONNAIRE";
export const FETCH_RULES = "FETCH_RULES";
export const DELETE_RULE = "DELETE_RULE";
export const ADD_RULE = "ADD_RULE";
export const UPDATE_RULE = "UPDATE_RULE";
export const FETCH_ALL_QUESTIONNAIRE = "FETCH_ALL_QUESTIONNAIRE";
export const FETCH_ALL_QUESTIONNAIRE_SUCCESS = "FETCH_ALL_QUESTIONNAIRE_SUCCESS";
export const FETCH_ALL_QUESTIONNAIRE_ERROR = "FETCH_ALL_QUESTIONNAIRE_ERROR";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const ADD_QUESTIONNAIRE = "ADD_QUESTIONNAIRE";
export const ADD_QUESTIONNAIRE_SUCCESS = "ADD_QUESTIONNAIRE_SUCCESS";
export const ADD_QUESTIONNAIRE_ERROR = "ADD_QUESTIONNAIRE_ERROR";
export const UPDATE_QUESTIONNAIRE = "UPDATE_QUESTIONNAIRE";
export const UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS";
export const UPDATE_QUESTIONNAIRE_ERROR = "UPDATE_QUESTIONNAIRE_ERROR";
export const RESET_QUESTIONNAIRE = "RESET_QUESTIONNAIRE";
export const ADD_RECOMENDATION = "ADD_RECOMENDATION";
export const UPDATE_RECOMENDATION = "UPDATE_RECOMENDATION";
export const FETCH_RECOMENDATIONS = "FETCH_RECOMENDATIONS";
export const DELETE_RECOMENDATION = "DELETE_RECOMENDATION";
export const FETCH_ALL_GRADE_RECOMENDATION = "FETCH_ALL_GRADE_RECOMENDATION";
export const FETCH_ALL_GRADE_RECOMENDATION_SUCCESS = "FETCH_GRADE_RECOMENDATION_SUCCESS";
export const FETCH_ALL_GRADE_RECOMENDATION_ERROR = "FETCH_GRADE_RECOMENDATION_ERROR";
export const ADD_GRADE_RECOMENDATION = "ADD_GRADE_RECOMENDATION";
export const ADD_GRADE_RECOMENDATION_SUCCESS = "ADD_GRADE_RECOMENDATION_SUCCESS";
export const ADD_GRADE_RECOMENDATION_ERROR = "ADD_GRADE_RECOMENDATION_ERROR";

export const UPDATE_GRADE_RECOMENDATION = "UPDATE_GRADE_RECOMENDATION";
export const UPDATE_GRADE_RECOMENDATION_SUCCESS = "UPDATE_GRADE_RECOMENDATION_SUCCESS";
export const UPDATE_GRADE_RECOMENDATION_ERROR = "UPDATE_GRADE_RECOMENDATION_ERROR";

export const DELETE_GRADE_RECOMENDATION = "DELETE_GRADE_RECOMENDATION";
export const DELETE_GRADE_RECOMENDATION_SUCCESS = "DELETE_GRADE_RECOMENDATION_SUCCESS";
export const DELETE_GRADE_RECOMENDATION_ERROR = "DELETE_GRADE_RECOMENDATION_ERROR";

export const FETCH_ROOT_FORM = "FETCH_ROOT_FORM";
export const FETCH_ROOT_FORM_SUCCESS = "FETCH_ROOT_FORM_SUCCESS";
export const FETCH_ROOT_FORM_ERROR = "FETCH_ROOT_FORM_ERROR";


export const FETCH_DEPENDENT_FORM = "FETCH_DEPENDENT_FORM";
export const FETCH_DEPENDENT_FORM_SUCCESS = "FETCH_DEPENDENT_FORM_SUCCESS";
export const FETCH_DEPENDENT_FORM_ERROR = "FETCH_DEPENDENT_FORM_ERROR";
